import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Single from "./component/single";
import Double from "./component/double";
import DoTable from "./components/do.table";
import TickIcon from "../../assets/images/tick_icon.png";

// Font.register({
//   family: "Times-Bold",
//   src: require("./fonts/times-bold.ttf"),
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 25, //35
    paddingBottom: 50, //65
    paddingHorizontal: 30, //35
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  image: {
    // width: "25%",
    marginVertical: 15,
    marginHorizontal: 200,
  },
  header: {
    fontSize: 12,
    marginBottom: 15, //20
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  smalltext: {
    fontSize: 8,
    textAlign: "center",
  },
  header: {
    fontSize: 12,
    backgroundColor: "#0E86D4",
    width: "90%",
    textAlign: "center",
    justifyContent: "center",
    margin: "0 auto",
    marginTop: 16,
    padding: 6,
    color: "white",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  fiveColumnBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  fiveColumnText: {
    alignSelf: "center",
    fontSize: 8.76,
    color: "black",
    // fontFamily: "Times",
  },
  symbolText: {
    alignSelf: "center",
    fontSize: 7.39,
    color: "black",
    // fontFamily: "Seguisym",
  },

  checkedText: {
    marginLeft: 70,
    marginTop: 21,
    fontSize: 9.68,
    color: "black",
    // fontFamily: "Times-Bold",
  },
  signText: {
    alignSelf: "center",
    marginTop: 5,
    fontSize: 9.68,
    color: "black",
    // fontFamily: "Times-Bold",
  },
  positionText: {
    alignSelf: "center",
    fontSize: 9.68,
    color: "black",
    // fontFamily: "Times-Bold",
  },
  ackText: {
    textAlign: "justify",
    fontSize: 9.68,
    color: "black",
    // fontFamily: "Times-Bold",
  },
  ackBottomText: {
    marginTop: 5,
    fontSize: 9.68,
    color: "black",
    // fontFamily: "Times-Bold",
  },
  borderContainer: {
    flex: 6,
    padding: 1,
    border: "2px solid black",
    marginTop: 25, //30
  },
});

const DOForm = ({ data }) => {
  const data_shown_name = data?.ap_detail?.shown_name;
  const renewal_quotation =
    data?.do?.renewal_quotation === "yes" ? true : false;

  const renderLogo = () => {
    switch (data_shown_name) {
      case "DCF":
        return require("../../assets/images/FDS-logo.png");
      case "DCFP":
        return require("../../assets/images/FP-logo.png");
      case "DCK":
        return require("../../assets/images/KR-logo.png");
      case "DCGS":
        return require("../../assets/images/GS-logo.JPG");
      case "DCP":
        return require("../../assets/images/DCP-Logo.jpg");
      case "DCHO":
        return require("../../assets/images/DCHO-logo.png");
      case "DCH AUTOMOBILE":
        return require("../../assets/images/DCH-logo.png");
      default:
        return require("../../assets/images/FDS-logo.png");
    }
  };

  const getAmorPm = () => {
    const current_date = new Date().toLocaleTimeString();
    const hour = current_date.split(":")[0];
    return Number(hour) > 12 ? "PM" : "AM";
  };
  const renderAddressAP = () => {
    const data_shown_name = data?.ap_detail?.shown_name;
    switch (data_shown_name) {
      case "DCF":
        return (
          <Text style={[styles.smalltext, { marginTop: 5 }]}>
            NO. 1, JALAN ASTAKA 4/KU2, BANDAR BUKIT RAJA, 41050 KLANG, SELANGOR
            DARUL EHSAN.
          </Text>
        );
      // return (
      //   <View style={{ marginTop: 15 }}>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       NO. 1, JALAN ASTAKA 4/KU2,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       BANDAR BUKIT RAJA,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       41050 KLANG,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       SELANGOR DARUL EHSAN.
      //     </Text>
      //   </View>
      // );
      case "DCFP":
        return (
          <Text style={[styles.smalltext, { marginTop: 5 }]}>
            No 3, JALAN ASTANA 1D, BANDAR BUKIT RAJA, 41050 KLANG, SELANGOR
          </Text>
        );
      // return (
      //   <View style={{ marginTop: 15 }}>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       No 3, JALAN ASTANA 1D,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       BANDAR BUKIT RAJA,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       41050 KLANG, SELANGOR
      //     </Text>
      //   </View>
      // );
      case "DCK":
        return (
          <Text style={[styles.smalltext, { marginTop: 5 }]}>
            22A, Jalan Astana 1B, Bandar Bukit Raja, 41050 Klang Selangor.
          </Text>
        );
      // return (
      //   <View style={{ marginTop: 15 }}>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       22A, Jalan Astana 1B,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       Bandar Bukit Raja,
      //     </Text>
      //     <Text
      //       style={{
      //         fontSize: 8,
      //         textTransform: "uppercase",
      //       }}
      //     >
      //       41050 Klang Selangor.
      //     </Text>
      //   </View>
      // );
      case "DCGS":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              No.3, Jalan Kenanga 1/1,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              Salak Perdana,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              43900 Sepang ,Selangor.
            </Text>
          </View>
        );
      case "DCP":
        return (
          <Text style={[styles.smalltext, { marginTop: 5 }]}>
            No.6, Jalan Astana 1B, Bandar Bukit Raja, 41050 Klang ,Selangor.
          </Text>
        );
      case "DCHO":
        return (
          <Text style={[styles.smalltext, { marginTop: 5 }]}>
            27, Jalan Pemberita U1/49, Hicom-glenmarie Industrial Park, 40150
            Shah Alam, Selangor
          </Text>
        );
      case "DCH AUTOMOBILE":
        return (
          <Text style={[styles.smalltext, { marginTop: 5 }]}>
            No.7, Jalan Astana 1F/KU2, Bandar Bukit Raja, 41050 Klang ,Selangor.
          </Text>
        );
      default:
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              NO. 1, JALAN ASTAKA 4/KU2,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              BANDAR BUKIT RAJA,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 KLANG,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              SELANGOR DARUL EHSAN.
            </Text>
          </View>
        );
    }
  };

  // const renderAddressAP = () => {
  //   // switch (data?.ap_id) {
  //   //   case 1:
  //   //     return (
  //   //       <Text style={[styles.smalltext, { marginTop: 5 }]}>
  //   //         {data?.ap_detail?.address}
  //   //       </Text>
  //   //     );
  //   //   case 2:
  //   //     return (
  //   //       <Text style={[styles.smalltext, { marginTop: 5 }]}>
  //   //         {data?.ap_detail?.address}
  //   //       </Text>
  //   //     );
  //   //   case 3:
  //   //     return (
  //   //       <Text style={[styles.smalltext, { marginTop: 5 }]}>
  //   //         {data?.ap_detail?.address}
  //   //       </Text>
  //   //     );
  //   //   default:
  //   return (
  //     <Text style={[styles.smalltext, { marginTop: 5 }]}>
  //       {data?.ap_detail?.address}
  //     </Text>
  //   );
  //   // }
  // };

  return (
    <Document>
      <Page style={styles.body}>
        <Image
          style={{
            ...styles.image,
            width: data.ap_shown_name == "DCF" ? "20%" : "25%",
          }}
          src={renderLogo()}
        />
        ;
        <Text
          style={[
            styles.smalltext,
            {
              // color: "#4E7DCD",
              fontSize: "14px", // fontFamily: "Oswald"
            },
          ]}
        >
          {data?.ap_detail?.name}
          <Text style={styles.smalltext}>({data?.ap_detail?.register_no})</Text>
        </Text>
        {renderAddressAP()}
        <Text style={styles.header}>DELIVERY ORDER FORM</Text>
        <Single title={"Customer Name"} value={data?.personal_name} />
        <Double
          payload={{
            title1: "BRANCH",
            value1: "BUKIT RAJA, KLANG",
            title2: "DATE",
            value2: " ",
          }}
        />
        <Double
          payload={{
            title1: "MODEL",
            value1: `${data?.vehicle_model ? data.vehicle_model : " "}`,
            title2: "YEAR MAKE",
            value2: data?.vehicle_year,
          }}
        />
        <Double
          payload={{
            title1: "CHASIS",
            value1: data?.vehicle_chasis_no,
            title2: "ENGINE",
            value2: data?.vehicle_engine_no,
          }}
        />
        <Double
          payload={{
            title1: "Car Plate",
            value1: data?.number_plate,
            title2: "MILEAGE",
            value2: data?.vehicle_mileage,
          }}
        />
        {/* <TableHeader /> */}
        <View
          style={{
            marginTop: 30, //35
          }}
        >
          <DoTable data={data} />
        </View>
        <div>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: -15,
              marginTop: 10,
            }}
          >
            <View style={{ flex: 6, padding: 1 }}></View>
            <View style={{ flex: 6, padding: 1 }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {/* Checkbox */}
                <View
                  style={{
                    width: 16,
                    height: 16,
                    borderWidth: 1,
                    borderColor: "#000", // Checkbox border color
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 5,
                  }}
                >
                  {/* Tick or empty based on renewal_quotation */}
                  {renewal_quotation && (
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        // backgroundColor: "#000", // Tick color
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={TickIcon}
                        style={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    </View>
                  )}
                </View>

                {/* Text */}
                <Text style={{ fontSize: 8.6 }}>
                  I would like to receive a renewal quotation for my car
                  insurance.
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 6, padding: 1 }}>
              <View style={{ marginTop: 30, marginBottom: 60 }}>
                <Text style={styles.checkedText}>Checked by :</Text>
              </View>
              <Text style={styles.positionText}>
                ---------------------------------------
              </Text>
              <Text style={styles.positionText}>(Sales Consultant)</Text>
              <Text style={styles.signText}>
                Name : {data?.sales_agent_name}
              </Text>
              <Text style={styles.signText}>
                {/* Date : {new Date().toLocaleDateString()} */}
                Date : {"              "}
              </Text>
              <Text style={styles.signText}>
                {/* Time : {getAmorPm()} */}
                Time : {"              "}
              </Text>
            </View>
            <View style={styles.borderContainer}>
              <Text style={styles.ackText}>
                {" "}
                I, {data?.personal_name} as owner of vehicle car plate.{" "}
                {data?.number_plate ? data.number_plate : " __________ "}{" "}
                acknowledge receive of the above mentioned item.
              </Text>

              {/* {renewal_quotation && (
                <Text
                  style={{
                    textAlign: "justify",
                    fontSize: 10,
                    color: "black",
                    marginTop: 10,
                  }}
                >
                  <Text>
                    {" "}
                    P.S. I would like to receive a renewal quotation for my car
                    insurance
                  </Text>
                </Text>
              )} */}

              <View style={{ marginTop: 70 }}>
                <Text style={styles.positionText}>
                  -----------------------------------------------------------------------------------
                </Text>
                <Text style={styles.positionText}>(Customer Signature)</Text>
                <Text style={styles.ackBottomText}>Name : </Text>
                <Text style={styles.ackBottomText}>I/c No. : </Text>
                <Text style={styles.ackBottomText}>
                  {/* Date : {new Date().toLocaleDateString()} Time : {getAmorPm()} */}
                  Date :
                </Text>
              </View>
            </View>
          </View>
        </div>
      </Page>
    </Document>
  );
};

export default DOForm;
